import React, { useContext } from "react";
import ForbiddenErrorPage from "../ForbiddenErrorPage";
import PropTypes from "prop-types";
import { UserContext } from "../AuthGuard";

export default function RoleGuard({
  roles = [],
  restrictedView = "",
  children,
}) {
  const [user] = useContext(UserContext);

  const hasAccess = () => {
    if (!user) return false;
    if (!roles.includes(user.role)) return false;
    if (restrictedView && !user?.features?.[restrictedView] && user.role !== "admin")
      return false;
    return true;
  };

  return hasAccess() ? children : <ForbiddenErrorPage />;
}

RoleGuard.propTypes = {
  roles: PropTypes.array,
};
