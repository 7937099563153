import React, { useState, useEffect, useContext } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Box,
  CircularProgress,
  IconButton,
  Drawer,
  List,
  ListItem,
  Chip,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { keyframes } from "@emotion/react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import logo from "../../assets/logo.svg";
import { UserContext } from "../AuthGuard";
import { signout } from "./sidebar/Sidebar";

const StyledMenuItem = styled(Typography)(({ theme, item }) => ({
  fontWeight:
    item.url === window.location.pathname.substring(1) ? "bold" : "normal",
  textDecorationLine: "none",
  marginTop: [4, 0],
  color: "#fff",
  cursor: "pointer",
  borderRadius: item.outline ? "25px" : null,
  border: item.outline ? "2px solid white" : null,
  padding: item.outline ? "5px 40px" : null,
  "&::before": {
    content: '""',
    position: "absolute",
    bottom: -5,
    left: 0,
    width:
      item.url === window.location.pathname.substring(1) && !item.outline
        ? "100%"
        : 0,
    height: "2px",
    backgroundColor: "white",
    transition: "width 0.3s ease",
  },
  "&:hover::before": {
    width: item.dropdownItems || item.outline ? 0 : "100%",
  },
}));

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const TopNavBar = ({ hasBackground = false }) => {
  const [user, setUser] = useContext(UserContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => setLoading(!user), [user]);

  const handleMenuOpen = (event, dropdownName) => {
    setActiveDropdown(dropdownName);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setActiveDropdown(null);
  };

  const changeLanguage = (lng) => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng);
    setDrawerOpen(false);
  };

  const menuItems = getMenuItems(t, changeLanguage);

  if (user?.role === "none") {
    menuItems.push({
      name: t("menu_logout"),
      action: () => signout(setUser, navigate),
      outline: true,
    });
  }

  const handleDrawerItemClick = (item) => {
    if (item.dropdownItems) {
      setOpenDropdowns((prev) => ({
        ...prev,
        [item.name]: !prev[item.name],
      }));
    } else {
      if (item.action) {
        item.action();
      } else if (item.url) {
        navigate(`/${item.url}`);
      }
      setDrawerOpen(false);
    }
  };

  return (
    <AppBar position="static" style={getAppBarStyle(hasBackground)}>
      <Toolbar
        sx={{
          flexWrap: "nowrap",
          justifyContent: "space-between",
        }}
      >
        <img
          src={logo}
          alt="logo"
          onClick={() => navigate("/")}
          style={getLogoStyle(process.env.REACT_APP_ENV)}
        />
        {process.env.REACT_APP_ENV !== "prod" && (
          <Chip
            size="small"
            variant="outlined"
            label={process.env.REACT_APP_ENV}
            sx={{
              ml: 1,
              fontWeight: "bold",
              textTransform: "uppercase",
              color: process.env.REACT_APP_ENV === "dev" ? "#ddff00" : "red",
              border: "3px solid",
              p: 1,
              mb: 0.5,
              animation: `${pulse} 2s infinite`,
              borderColor:
                process.env.REACT_APP_ENV === "dev" ? "lime" : "rgb(255 163 0)",
            }}
          />
        )}
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          sx={{
            display: {
              xs: "block",
              md: "none",
            },
          }}
          onClick={() => setDrawerOpen(true)}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexGrow: 1,
            justifyContent: "flex-end",
            flexDirection: ["column", "row"],
            textAlign: ["center", "right"],
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            menuItems
              .filter((item) => !item.role || item.role.includes(user?.role))
              .map((item) =>
                renderMenuItem(
                  item,
                  handleMenuOpen,
                  handleMenuClose,
                  () => setDrawerOpen(false),
                  activeDropdown,
                  anchorEl,
                  user,
                ),
              )
          )}
        </Box>
      </Toolbar>
      {drawerOpen && (
        <Drawer
          anchor="right"
          open={true}
          onClose={() => setDrawerOpen(false)}
          sx={{ width: "70%", color: "#fff" }}
          PaperProps={{
            style: {
              maxWidth: "none",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "#001556",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "8px",
              }}
            >
              <IconButton onClick={() => setDrawerOpen(false)}>
                <CloseIcon style={{ color: "#fff" }} />
              </IconButton>
            </Box>
            <List>
              {loading ? (
                <CircularProgress />
              ) : (
                menuItems
                  .filter((item) => !item.role || item.role.includes(user?.role))
                  .map((item, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        onClick={() => handleDrawerItemClick(item)}
                        sx={{
                          color: "#fff",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {item.name}
                        {item.dropdownItems &&
                          (openDropdowns[item.name] ? (
                            <ArrowDropUp />
                          ) : (
                            <ArrowDropDown />
                          ))}
                      </ListItem>
                      {item.dropdownItems &&
                        openDropdowns[item.name] &&
                        item.dropdownItems
                          .filter(
                            (subItem) =>
                              !subItem.role || subItem.role.includes(user?.role),
                          )
                          .map((subItem, subIndex) => (
                            <ListItem
                              key={subIndex}
                              onClick={
                                subItem.link
                                  ? () =>
                                      window.open(subItem.link, "_blank").focus()
                                  : () => handleDrawerItemClick(subItem)
                              }
                              sx={{
                                color: "#fff",
                                paddingLeft: "2em",
                              }}
                            >
                              {subItem.name}
                            </ListItem>
                          ))}
                    </React.Fragment>
                  ))
              )}
            </List>
          </Box>
        </Drawer>
      )}
    </AppBar>
  );
};

const getMenuItems = (t, changeLanguage) => {
  const itemsForGuest = [
    {
      name: t("menu_language"),
      dropdownItems: [
        { name: "English", action: () => changeLanguage("en") },
        { name: "Nederlands", action: () => changeLanguage("nl") },
        { name: "Français", action: () => changeLanguage("fr") },
        { name: "Deutsch", action: () => changeLanguage("de") },
      ],
    },
    { name: t("menu_support"), url: "team" },
    { name: t("menu_about"), url: "about" },
    { name: t("menu_news"), url: "news" },
    { name: t("menu_login"), url: "login", outline: true },
  ];

  return itemsForGuest;
};

const getAppBarStyle = (hasBackground) => {
  const path = window.location.pathname.split("/")[1];
  const transparentPaths = ["", "team", "about", "news", "news-post"];
  return {
    background: transparentPaths.includes(path)
      ? "transparent"
      : "linear-gradient(100deg, rgb(0, 18, 85) 50%, rgb(115 221 201) 100%)",
    boxShadow: "none",
    position: "absolute",
  };
};

const getLogoStyle = (env) => {
  const css = {
    cursor: "pointer",
    userSelect: "none",
    marginTop: "24px",
    marginBottom: "24px",
    maxWidth: "200px",
    margin: "16px 0px 16px 0px",
  };

  if (env !== "prod") {
    css.filter =
      process.env.REACT_APP_ENV === "dev"
        ? "sepia(100%) hue-rotate(13deg) saturate(10000%)"
        : "sepia(100%) hue-rotate(297deg) saturate(10000%)";
  }

  return css;
};

const renderMenuItem = (
  item,
  handleMenuOpen,
  handleMenuClose,
  closeDrawer,
  activeDropdown,
  anchorEl,
  user,
) => {
  const isDropdown = item.dropdownItems && item.dropdownItems.length > 0;

  const handleItemClick = () => {
    if (item.action) {
      item.action();
    }
    closeDrawer();
  };

  return (
    <Box
      key={item.name}
      onMouseEnter={
        isDropdown ? (event) => handleMenuOpen(event, item.name) : null
      }
      onMouseLeave={isDropdown ? handleMenuClose : null}
      sx={{ position: "relative", m: 1.5 }}
    >
      <StyledMenuItem
        variant="body1"
        component={isDropdown ? "div" : Link}
        to={isDropdown ? null : "/" + getUrlFromName(item)}
        onClick={handleItemClick}
        item={item}
      >
        {item.name}
      </StyledMenuItem>
      {isDropdown &&
        renderDropdownMenu(
          item,
          handleMenuClose,
          activeDropdown,
          anchorEl,
          user,
        )}
    </Box>
  );
};

const getUrlFromName = (item) => {
  return item.url ? item.url : item.name.toLowerCase().replace(" ", "-");
};

const renderDropdownMenu = (
  item,
  handleMenuClose,
  activeDropdown,
  anchorEl,
  user,
) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={activeDropdown === item.name}
      onClose={handleMenuClose}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      sx={{
        width: "fit-content",
        height: "auto",
      }}
      disableRestoreFocus
      hideBackdrop
      slotProps={{
        paper: {
          elevation: 3,
          sx: {
            backgroundColor: "#fff",
            borderRadius: "4px",
            minWidth: "200px",
            pl: "10px",
            pr: "10px",
          },
        },
      }}
    >
      {item.dropdownItems
        .filter((subItem) => !subItem.role || subItem.role.includes(user?.role))
        .map((dropdownItem) => (
          <div key={dropdownItem.name}>
            {dropdownItem.hasDividerTop && <Divider />}
            <MenuItem
              key={dropdownItem.name}
              component={Link}
              to={
                dropdownItem.action || dropdownItem.link
                  ? null
                  : "/" + getUrlFromName(dropdownItem)
              }
              color="warning"
              onClick={
                dropdownItem.link
                  ? () => {
                      window.open(dropdownItem.link, "_blank").focus();
                    }
                  : () => {
                      if (dropdownItem.action) {
                        dropdownItem.action();
                      }
                    }
              }
              sx={{
                borderRadius: "5px",
                color: dropdownItem.color ? dropdownItem.color : "inherit",
              }}
            >
              {dropdownItem.icon && dropdownItem.icon}
              {dropdownItem.name}
              <br />
            </MenuItem>
            {dropdownItem.hasDividerBottom && <Divider />}
          </div>
        ))}
    </Menu>
  );
};

TopNavBar.propTypes = {
  hasBackground: PropTypes.bool,
  inputUser: PropTypes.object,
};

export default React.memo(TopNavBar);
